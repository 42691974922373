import React from 'react';
import styled from 'styled-components';
import { HeadPage, SubTitle, NormalText } from 'shared/components';

const Wrapper = styled.div``;

const Title = styled(SubTitle)`
  margin: 15px 0px;
`;

const Text = styled(NormalText)`
  margin: 5px 0px;
`;

const Terms = (): JSX.Element => {
  return (
    <Wrapper>
      <HeadPage>
        <SubTitle>תנאי שימוש באתר תוצרת הבקעה</SubTitle>
      </HeadPage>

      <Text>תקנון השימוש באתר הנ"ל נכתב בלשון זכר אך האמור בו מתייחס לנשים וגברים כאחד.</Text>

      <Title>1. קדימון</Title>
      <Text>
        2. אתר "תוצרת הבקעה" (להלן "האתר") הוא אתר המשתמש כאתר למכירת תוצרת חקלאית מהבקעה והנך מוזמן
        לקחת בו חלק בכפוף להסכמתך לתנאי השימוש אשר יפורטו להלן.
      </Text>

      <Text>
        3. בנוסף השימוש באתר זה על כל תכניו והשירותים המוצעים בו, הורדות של קבצים, מדיה כגון תמונות
        וסרטונים והתכנים השונים המוצעים לקהל המבקרים עשויים להשתנות מעת לעת או בהתאם לסוג התוכן.
      </Text>

      <Text>
        4. הנהלת האתר שומרת לעצמה הזכות לעדכן את תנאי השימוש המוצגים להלן מעת לעת וללא התראה או
        אזכור מיוחד בערוצי האתר השונים.
      </Text>

      <Title>5. קניין רוחני</Title>
      <Text>
        6. האתר כמו גם כל המידע שבו לרבות עיצוב האתר, קוד האתר, קבצי מדיה לרבות גרפיקות, סרטונים,
        תמונות, טקסטים, קבצים המוצעים להורדה וכל חומר אחר אשר מוצג באתר שייכים במלואם לאתר הנ"ל
        ומהווים קניין רוחני בלעדי של אתר תוצרת הבקעה ואין לעשות בהם שימוש ללא אישור כתוב מראש מאתר
        תוצרת הבקעה.
      </Text>

      <Text>
        7. בנוסף אין להפיץ, להעתיק, לשכפל, לפרסם, לחקות או לעבד פיסות קוד, גרפיקות, סרטונים, סימנים
        מסחריים או כל מדיה ותוכן אחר מבלי שיש ברשותכם אישור כתוב מראש.
      </Text>

      <Title>8. תוכן האתר</Title>
      <Text>
        9. אנו שואפים לספק לכם את המידע המוצג באתר ללא הפרעות אך יתכנו בשל שיקולים טכניים, תקלות צד
        ג או אחרים, הפרעות בזמינות האתר. ולכן איננו יכולים להתחייב כי האתר יהיה זמין לכם בכל עת ולא
        יינתן כל פיצוי כספי או אחר בשל הפסקת השירות / הורדת האתר.
      </Text>

      <Text>
        10. קישורים לאתר חיצוניים אינם מהווים ערובה כי מדובר באתרים בטוחים, איכותיים או אמינים
        וביקור בהם נעשה על דעתכם האישית בלבד ונמצאים בתחום האחריות הבלעדי של המשתמש באתר.
      </Text>

      <Text>
        11. התכנים המוצעים באתר הינם בבעלותם הבלעדית של תוצרת הבקעה ואין לעשות בהם שימוש אשר נועד את
        האמור בתקנון זה (ראה סעיף 3) למעט במקרים בהם צוין אחרת או במקרים בהם צוין כי זכויות היוצרים
        שייכים לגוף חיצוני. במקרים אלו יש לבדוק מהם תנאי השימוש בקישור המצורף ולפעול על פי המצוין
        באתר החיצוני לו שייכים התכנים.
      </Text>

      <Title>12. ניהול משתמשים ומבקרים באתר</Title>

      <Text>
        13. הנהלת האתר שומרת לעצמה את הזכות לחסום כל משתמש בין אם על ידי חסימת כתובת ה IP של המחשב
        שלו, כתובת ה MACID של המחשב שלו או אפילו בהתאם למדינת המוצא ללא צורך לספק תירוץ אשר מקובל על
        הגולש.
      </Text>

      <Text>
        14. צוות האתר / הנהלת האתר יעשה כל שביכולתו להגן על פרטי המשתמשים הרשומים באתר / מנויים
        הרשומים באתר. במקרים בהם יעלה בידיו של צד שלישי להשיג גישה למידע מוסכם בזאת כי לגולשים,
        משתמשים וחברים באתר לה תהה כל תביעה, טענה או דרישה כלפי צוות האתר תוצרת הבקעה.
      </Text>

      <Text>
        15. צוות האתר שומר לעצמו את הזכות לעשות שימוש במידע שהגולשים נותנים לו על מנת לפרסם ימי
        מכירה נוספים.
      </Text>

      <Title>16. גילוי נאות</Title>
      <Text>
        18. בכל העת ולבד מאשר גולשים המחוברים לאתר המידע הנשמר הוא אנונימי לחלוטין ואין בו את שם
        הגולש או כל פרט מזהה אחר
      </Text>

      <Title>19. איזור שיפוט</Title>

      <Text>
        20. בעת שאתם עושים שימוש באתר ובמקרה בו התגלעה כל מחולקת אתם מסכימים להלן כי האמור לעיל נמצא
        תחת סמכות השיפוט הבלעדי של החוק הישראלי תוך שימוש במערכת בתי המשפט הישראליים בלבד במחוז תל
        אביב.
      </Text>

      <Title>21. מדניות אספקה</Title>

      <Text>
        22. שיטת האספקה של תוצרת הבקעה היא חלוקה מרוכזת של הסחורה ליישובים ונקודות בארץ בתאריכים
        קבועים מראש.
      </Text>

      <Text>23. תוצרת הבקעה מספקים את התוצרת לנקודות החלוקה המוצגות באתר בלבד.</Text>

      <Text>
        24. אספקת התוצרת החקלאית תהיה כפופה לתאריכים שאותם האתר איפשר לבחירה, תוצרת הבקעה אינם
        מחויבים לספק את הסחורה מלבד באותם התאריכים בלבד בהתאם לבחירת הלקוח.
      </Text>

      <Text>
        25. אספקת התוצרת החקלאית ביום החלוקה כפופה לשעות החלוקה המוצגות לכל נקודת חלוקה. בהתאם
        לנקודת החלוקה אותה בחר הלקוח בלבד.
      </Text>
    </Wrapper>
  );
};

export default Terms;
